import { SHIPMENT_SEARCH } from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const paramsReducer = (state = initialState.params, action) => {
  switch (action.type) {
    case SHIPMENT_SEARCH:
      return action.updateSearchParams ? action.params : state;

    default:
      return state;
  }
};

export default paramsReducer;
