import { combineReducers } from "redux";

import settingsReducer from "./settings.reducer";
import themesReducer from "./themes.reducers";
import { errorReducer, errorsReducer } from "./errors.reducers";
import loadingReducer from "./loading.reducers";
import paramsReducer from "./params.reducers";
import sortsReducer from "./sorts.reducers";
import shipmentsReducer from "./shipments.reducers";
import cartReducer from "./cart.reducers";
import paymentReducer from "./payment.reducers";
import wizardReducer from "./wizard.reducers";
import variantReducer from "./variant.reducer";
import redirectReducer from "./redirect.reducer";

import {
  authenticateReducer,
  authenticationResultReducer,
  passwordRecoveryReducer,
  countryListReducer,
  placeSearchReducer,
  holidaysReducer,
  shipmentRateReducer,
  userStoreReducer,
  registrationReducer,
  invoicesReducer,
} from "./api.reducers";

import {
  senderReducer,
  receiverReducer,
  shippingReducer,
  servicesReducer,
  quotationReducer,
} from "./order.reducers";

export default combineReducers({
  loading: loadingReducer,
  params: paramsReducer,
  sorts: sortsReducer,
  settings: settingsReducer,
  theme: themesReducer,
  user: authenticateReducer,
  calendar_holidays: holidaysReducer,
  wizard: wizardReducer,
  order: combineReducers({
    quotation: quotationReducer,
    from: senderReducer,
    to: receiverReducer,
    services: servicesReducer,
    shipping: shippingReducer,
  }),
  variant: variantReducer,
  cart: cartReducer,
  payment: paymentReducer,
  error: errorReducer,
  errors: errorsReducer,
  country_list: countryListReducer,
  places: placeSearchReducer,
  quotations: shipmentRateReducer,
  shipments: shipmentsReducer,
  user_store: userStoreReducer,
  invoices: invoicesReducer,
  error_authentication_failure: authenticationResultReducer,
  error_invalid_credential: registrationReducer,
  error_invalid_recovery_link: passwordRecoveryReducer,
  redirectUrl: redirectReducer,
});
