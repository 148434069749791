import Security from "../../api/security";
import Geonames from "../../api/geonames";
import Geodb from "../../api/geodb";
import Calendar from "../../api/calendar";
import Shipment from "../../api/shipment";
import User from "../../api/user";
import Subscription from "../../api/subscription";
import Registration from "../../api/registration";
import Logger from "../../api/logger";
import Quotation from "../../api/quotation";
import Cart from "../../api/cart";
import Billing from "../../api/billing";

import HttpStatusCode from "../../api/httpstatus";
import configuration from "../../configs/config.json";

export const REAUTHENTICATE_SUCCESS = "REAUTHENTICATE_SUCCESS";
export const REAUTHENTICATE_STARTED = "REAUTHENTICATE_STARTED";
export const REAUTHENTICATE_FAILURE = "REAUTHENTICATE_FAILURE";

export const AUTHENTICATE_INIT = "AUTHENTICATE_INIT";
export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_STARTED = "AUTHENTICATE_STARTED";
export const AUTHENTICATE_FAILURE = "AUTHENTICATE_FAILURE";

export const RESET_PASSWORD_STARTED = "PASSWORD_RECOVERY_STARTED";
export const RESET_PASSWORD_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const RESET_PASSWORD_FAILURE = "PASSWORD_RECOVERY_FAILURE";

export const CHANGE_PASSWORD_INIT = "CHANGE_PASSWORD_INIT";
export const CHANGE_PASSWORD_STARTED = "CHANGE_PASSWORD_STARTED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const REGISTRATION_STARTED = "REGISTRATION_STARTED";
export const REGISTRATION_FAILURE = "REGISTRATION_FAILURE";

export const COUNTRY_LIST_STARTED = "COUNTRY_LIST_STARTED";
export const COUNTRY_LIST = "COUNTRY_LIST";
export const COUNTRY_LIST_ERROR = "COUNTRY_LIST_ERROR";

export const PLACE_SEARCH_STARTED = "PLACE_SEARCH_STARTED";
export const PLACE_SEARCH = "PLACE_SEARCH";
export const PLACE_SEARCH_ERROR = "PLACE_SEARCH_ERROR";

export const USERSTORE_PUT_STARTED = "USERSTORE_PUT_STARTED";
export const USERSTORE_PUT = "USERSTORE_PUT";
export const USERSTORE_PUT_ERROR = "USERSTORE_PUT_ERROR";

export const USERSTORE_GET_STARTED = "USERSTORE_GET_STARTED";
export const USERSTORE_GET = "USERSTORE_GET";
export const USERSTORE_GET_ERROR = "USERSTORE_GET_ERROR";

export const USERSTORE_DELETE_STARTED = "USERSTORE_DELETE_STARTED";
export const USERSTORE_DELETE = "USERSTORE_DELETE";
export const USERSTORE_DELETE_ERROR = "USERSTORE_DELETE_ERROR";

export const USERSTORE_CLEAR = "USERSTORE_CLEAR";

export const CALENDAR_HOLIDAYS_STARTED = "CALENDAR_HOLIDAYS_STARTED";
export const CALENDAR_HOLIDAYS = "CALENDAR_HOLIDAYS";
export const CALENDAR_HOLIDAYS_ERROR = "CALENDAR_HOLIDAYS_ERROR";

export const SHIPMENT_RATE_STARTED = "SHIPMENT_RATE_STARTED";
export const SHIPMENT_RATE = "SHIPMENT_RATE";
export const SHIPMENT_RATE_ERROR = "SHIPMENT_RATE_ERROR";

export const SHIPMENT_SEARCH_STARTED = "SHIPMENT_SEARCH_STARTED";
export const SHIPMENT_SEARCH = "SHIPMENT_SEARCH";
export const SHIPMENT_SEARCH_ERROR = "SHIPMENT_SEARCH_ERROR";

export const QUOTATION_APPROVE_STARTED = "QUOTATION_APPROVE_STARTED";
export const QUOTATION_APPROVE = "QUOTATION_APPROVE";
export const QUOTATION_APPROVE_ERROR = "QUOTATION_APPROVE_ERROR";

export const SHIPMENT_CHANGE_STATUS_AND_REFRESH_STARTED =
  "SHIPMENT_CHANGE_STATUS_AND_REFRESH_STARTED";
export const SHIPMENT_CHANGE_STATUS_STARTED = "SHIPMENT_CHANGE_STATUS_STARTED";
export const SHIPMENT_CHANGE_STATUS = "SHIPMENT_CHANGE_STATUS";
export const SHIPMENT_CHANGE_STATUS_ERROR = "SHIPMENT_CHANGE_STATUS_ERROR";

export const GET_CART_SUMMARY_STARTED = "GET_CART_SUMMARY_STARTED";
export const GET_CART_SUMMARY = "GET_CART_SUMMARY";
export const GET_CART_SUMMARY_ERROR = "GET_CART_SUMMARY_ERROR";

export const ADD_TO_CART_STARTED = "ADD_TO_CART_STARTED";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_ERROR = "ADD_TO_CART_ERROR";

export const REMOVE_FROM_CART_STARTED = "REMOVE_FROM_CART_STARTED";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_FROM_CART_ERROR = "REMOVE_FROM_CART_ERROR";

export const ADD_ALL_TO_CART_STARTED = "ADD_ALL_TO_CART_STARTED";
export const ADD_ALL_TO_CART = "ADD_ALL_TO_CART";
export const ADD_ALL_TO_CART_ERROR = "ADD_ALL_TO_CART_ERROR";

export const REMOVE_ALL_FROM_CART_STARTED = "REMOVE_ALL_FROM_CART_STARTED";
export const REMOVE_ALL_FROM_CART = "REMOVE_ALL_FROM_CART";
export const REMOVE_ALL_FROM_CART_ERROR = "REMOVE_ALL_FROM_CART_ERROR";

export const BUY_CART_STARTED = "BUY_CART_STARTED";
export const BUY_CART = "BUY_CART";
export const BUY_CART_FAILED = "BUY_CART_FAILED";

export const CONFIRM_PAYMENT_STARTED = "CONFIRM_PAYMENT_STARTED";
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const CONFIRM_PAYMENT_ERROR = "CONFIRM_PAYMENT_ERROR";

export const DISMISS_PAYMENT_STARTED = "DISMISS_PAYMENT_STARTED";
export const DISMISS_PAYMENT = "DISMISS_PAYMENT";
export const DISMISS_PAYMENT_ERROR = "DISMISS_PAYMENT_ERROR";

export const SHIPMENT_DELETE_STARTED = "SHIPMENT_DELETE_STARTED";
export const SHIPMENT_DELETE = "SHIPMENT_DELETE";
export const SHIPMENT_DELETE_ERROR = "SHIPMENT_DELETE_ERROR";

export const INVOICES_GET_STARTED = "INVOICES_GET_STARTED";
export const INVOICES_GET = "INVOICES_GET";
export const INVOICES_GET_ERROR = "INVOICES_GET_ERROR";

export const SUBSCRIBE = "SUBSCRIBE";

export const ERROR_DISPLAY = "ERROR_DISPLAY";
export const LOGOUT = "LOGOUT";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const REDIRECTING = "REDIRECTING";

export const UPDATE_USER = "UPDATE_USER";

export const LOG_ERROR = "LOG_ERROR";

const redirectAfterSeconds = (redirectUrl, secs = 1) => {
  setTimeout(function () {
    window.location = redirectUrl;
  }, secs * 1000);
};

export const check = (dispatch, response) => {
  if (response.error || response.data === "") {
    if (response.status === HttpStatusCode.Unauthorized) {
      dispatch({ type: LOGOUT });
    } else {
      dispatch({
        type: ERROR_DISPLAY,
        payload: response,
      });
    }
    return false;
  }
  return true;
};

export const checker = (response) => {
  if (response.error) {
    if (response.status === HttpStatusCode.Unauthorized) {
      return [false, { type: LOGOUT }];
    } else {
      return [
        false,
        {
          type: ERROR_DISPLAY,
          payload: response,
        },
      ];
    }
  }
  return [true, null];
};

export const error = (e) => async (dispatch) => {
  dispatch({
    type: ERROR_DISPLAY,
    payload: e,
  });
};

export const authenticate_init = () => async (dispatch) => {
  dispatch({
    type: AUTHENTICATE_INIT,
    payload: {},
  });
};

export const authenticate = (email, password) => async (dispatch) => {
  dispatch({
    type: AUTHENTICATE_STARTED,
  });
  const response = await Security.authenticate(email, password);
  if (response.error) {
    dispatch({
      type: AUTHENTICATE_FAILURE,
      payload: response,
    });
    dispatch({
      type: LOGOUT,
      payload: response,
    });
  } else {
    dispatch({
      type: AUTHENTICATE_SUCCESS,
      payload: response,
    });
  }
};

export const sign_up = (user) => async (dispatch) => {
  dispatch({
    type: AUTHENTICATE_STARTED,
  });
  const response = await Security.sign_up(user);
  if (response.error) {
    dispatch({
      type: AUTHENTICATE_FAILURE,
      payload: response,
    });
  } else {
    dispatch({
      type: AUTHENTICATE_SUCCESS,
      payload: response,
    });
  }
};

export const autosense = () => async (dispatch) => {
  dispatch({
    type: REAUTHENTICATE_STARTED,
  });
  const response = await Security.autosense();
  if (response.error) {
    dispatch({
      type: LOGOUT,
      payload: response,
    });
  } else {
    dispatch({
      type: REAUTHENTICATE_SUCCESS,
      payload: response,
    });
  }
};

export const reset_password = (email) => async (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_STARTED,
  });
  const response = await Security.reset_password(email);
  if (response.error) {
    dispatch({
      type: RESET_PASSWORD_FAILURE,
      payload: response,
    });
  } else {
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: response,
    });
  }
};

export const change_password = (token, password) => async (dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD_STARTED,
  });
  const response = await Security.change_password(token, password);
  if (response.error) {
    dispatch({
      type: CHANGE_PASSWORD_FAILURE,
      payload: response,
    });
  } else {
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: response,
    });
  }
};

export const change_password_init = () => async (dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD_INIT,
    payload: {},
  });
};

export const registration = (name, username, password) => async (dispatch) => {
  const response = await Registration.registration(name, username, password);
  if (response.status === HttpStatusCode.UnprocessableEntity) {
    dispatch({
      type: REGISTRATION_FAILURE,
    });
  } else {
    dispatch({
      type: AUTHENTICATE_SUCCESS,
      payload: response,
    });
  }
};

export const sign_up_for_partner = (data, redirectUrl) => async (dispatch) => {
  const promise = await Security.sign_up(data);
  if (check(dispatch, promise)) {
    dispatch({
      type: SIGNUP_SUCCESS,
      data: redirectUrl,
    });
  } else {
    dispatch({
      type: SIGNUP_FAILURE,
      payload:
        promise && promise.response && promise.response.data
          ? promise.response.data.response
          : promise,
      data,
    });
  }
};

export const redirectTo = (redirectUrl) => async (dispatch) => {
  redirectAfterSeconds(redirectUrl);
  dispatch({ type: REDIRECTING });
};

export const update_profile =
  (
    id,
    name,
    address,
    postalcode,
    city,
    province,
    vat_code,
    pec,
    sdi_code,
    bank,
    bank_account_owner,
    bank_account,
    split_payment
  ) =>
  async (dispatch) => {
    const promise = await Security.update_profile(
      id,
      name,
      address,
      postalcode,
      city,
      province,
      vat_code,
      pec,
      sdi_code,
      bank,
      bank_account_owner,
      bank_account,
      split_payment
    );
    if (check(dispatch, promise)) {
      redirectAfterSeconds("/company-profile");
    } else {
      dispatch(autosense());
    }
  };

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const countryList =
  ({ lang }) =>
  async (dispatch) => {
    dispatch({
      type: COUNTRY_LIST_STARTED,
    });
    const promise = await Geonames.country_list({ fields: "name", lang });
    if (check(dispatch, promise)) {
      dispatch({
        type: COUNTRY_LIST,
        payload: promise.data.response.data,
      });
    } else {
      dispatch({
        type: COUNTRY_LIST_ERROR,
      });
    }
  };

export const placeSearch =
  ({ party, country_code, sequence }) =>
  async (dispatch) => {
    dispatch({ type: PLACE_SEARCH_STARTED, party });
    const promise = await Geodb.place_search({ country_code, sequence });
    if (check(dispatch, promise)) {
      dispatch({
        type: PLACE_SEARCH,
        payload: promise.data.response.data,
        party,
      });
    } else {
      dispatch({ type: PLACE_SEARCH_ERROR, party });
    }
  };

export const holidays =
  (year, country, timestamp, lat, lng) => async (dispatch) => {
    dispatch({ type: CALENDAR_HOLIDAYS_STARTED });
    const promise = await Calendar.holidays({
      year,
      country,
      timestamp,
      lat,
      lng,
    });
    if (check(dispatch, promise)) {
      dispatch({
        type: CALENDAR_HOLIDAYS,
        payload: promise.data.response.data,
      });
    } else {
      dispatch({
        type: CALENDAR_HOLIDAYS_ERROR,
      });
    }
  };

export const shipmentRate = (order) => async (dispatch) => {
  const { rated, from, to, shipping, services } = order;
  if (!rated) {
    dispatch({
      type: SHIPMENT_RATE_STARTED,
    });
    const promise = await Shipment.rate({ from, to, shipping, services });
    if (check(dispatch, promise)) {
      dispatch({
        type: SHIPMENT_RATE,
        payload: promise.data.response.data,
      });
    } else {
      dispatch({
        type: SHIPMENT_RATE_ERROR,
      });
    }
  }
};

export const userStorePut = (party, category, value) => async (dispatch) => {
  dispatch({ type: USERSTORE_PUT_STARTED, payload: { category, party } });
  const promise = await User.store_put(category, value);
  if (check(dispatch, promise)) {
    dispatch({
      type: USERSTORE_PUT,
      payload: { category, party, data: promise.data.response.data },
    });
  } else {
    dispatch({
      type: USERSTORE_PUT_ERROR,
      payload: { category, party },
    });
  }
};

export const userStoreGet =
  (party, category, search, filter = {}, most_frequent = false) =>
  async (dispatch) => {
    dispatch({
      type: USERSTORE_GET_STARTED,
      payload: { category, search },
      party,
    });
    const promise = await User.store_get(
      category,
      search,
      filter,
      most_frequent
    );
    if (check(dispatch, promise)) {
      dispatch({
        type: USERSTORE_GET,
        payload: { category, data: promise.data.response.data },
        party,
      });
    } else {
      dispatch({
        type: USERSTORE_GET_ERROR,
        payload: { category },
        party,
      });
    }
  };

export const userStoreDelete = (party, category, value) => async (dispatch) => {
  dispatch({ type: USERSTORE_DELETE_STARTED, payload: { category }, party });
  const promise = await User.store_put(category, value);
  if (check(dispatch, promise)) {
    dispatch({
      type: USERSTORE_DELETE,
      payload: { category, data: promise.data.response.data },
      party,
    });
  } else {
    dispatch({
      type: USERSTORE_DELETE_ERROR,
      payload: { category },
      party,
    });
  }
};

export const userStoreClear = (party, category) => async (dispatch) => {
  dispatch({
    type: USERSTORE_CLEAR,
    payload: { category },
    party,
  });
};

export const quotationApprove =
  (quotation_id, status, order = {}) =>
  async (dispatch) => {
    dispatch({
      type: QUOTATION_APPROVE_STARTED,
      payload: { quotation_id, status },
    });
    const promise = await Quotation.approve(quotation_id, status, order);
    if (check(dispatch, promise)) {
      dispatch({
        type: QUOTATION_APPROVE,
        payload: { data: promise.data.response.data },
      });
      const tab = status === "Draft" ? "drafts" : "to-be-paid";
      window.location.href = `/shipments#tab=${tab}`;
    } else {
      dispatch({
        type: QUOTATION_APPROVE_ERROR,
      });
    }
  };

export const shipmentChangeStatus =
  (party, shipment_id, status) => async (dispatch) => {
    dispatch({
      type: SHIPMENT_CHANGE_STATUS_STARTED,
      party,
      payload: { shipment_id, status },
    });
    const promise = await Shipment.status_put(shipment_id, status);
    if (check(dispatch, promise)) {
      dispatch({
        type: SHIPMENT_CHANGE_STATUS,
        payload: { data: promise.data.response.data },
      });
    } else {
      dispatch({
        type: SHIPMENT_CHANGE_STATUS_ERROR,
        party,
        payload: { shipment_id },
      });
    }
  };

export const shipmentChangeStatusAndRefresh =
  (section, id, status, params, sorts) => async (dispatch) => {
    dispatch({
      type: SHIPMENT_CHANGE_STATUS_AND_REFRESH_STARTED,
      section,
      id,
      status,
      params,
      sorts,
    });
  };

export const shipmentSearch =
  (party, params, sorts, updateSearchParams) => async (dispatch) => {
    dispatch({ type: SHIPMENT_SEARCH_STARTED });
    const promise = await Shipment.search({
      ...params,
      inspect: party === "inspection",
    });
    if (check(dispatch, promise)) {
      dispatch({
        type: SHIPMENT_SEARCH,
        party,
        params,
        sorts,
        updateSearchParams,
        payload: promise.data.response.data,
      });
    } else {
      dispatch({
        type: SHIPMENT_SEARCH_ERROR,
      });
    }
  };

export const shipmentSearchAndGetCartSummary =
  (party, params, sorts, updateSearchParams) => async (dispatch) => {
    dispatch({ type: SHIPMENT_SEARCH_STARTED });
    const promise = await Shipment.search(params);
    if (check(dispatch, promise)) {
      dispatch({
        type: SHIPMENT_SEARCH,
        party,
        params,
        sorts,
        updateSearchParams,
        payload: promise.data.response.data,
      });
      dispatch({ type: GET_CART_SUMMARY_STARTED });
      const promise2 = await Cart.summary_get();
      if (check(dispatch, promise2)) {
        dispatch({
          type: GET_CART_SUMMARY,
          payload: promise2.data.response.data,
          party: "to-be-paid",
        });
      }
    } else {
      dispatch({
        type: GET_CART_SUMMARY_ERROR,
      });
    }
  };

export const getCartSummary = () => async (dispatch) => {
  dispatch({ type: GET_CART_SUMMARY_STARTED });
  const promise = await Cart.summary_get();
  if (check(dispatch, promise)) {
    dispatch({
      type: GET_CART_SUMMARY,
      payload: promise.data.response.data,
      party: "to-be-paid",
    });
  } else {
    dispatch({
      type: GET_CART_SUMMARY_ERROR,
    });
  }
};

export const addToCart = (shipment_id) => async (dispatch) => {
  dispatch({ type: ADD_TO_CART_STARTED, payload: shipment_id });
  const promise = await Cart.shipment_add(shipment_id);
  if (check(dispatch, promise)) {
    dispatch({
      type: ADD_TO_CART,
      payload: promise.data.response.data,
      shipment_id,
      party: "to-be-paid",
    });
  } else {
    dispatch({
      type: ADD_ALL_TO_CART_ERROR,
    });
  }
};

export const removeFromCart = (shipment_id) => async (dispatch) => {
  dispatch({ type: REMOVE_FROM_CART_STARTED, payload: shipment_id });
  const promise = await Cart.shipment_remove(shipment_id);
  if (check(dispatch, promise)) {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: promise.data.response.data,
      shipment_id,
      party: "to-be-paid",
    });
  } else {
    dispatch({
      type: REMOVE_FROM_CART_ERROR,
    });
  }
};

export const addAllToCart = () => async (dispatch) => {
  const bookable = Math.round(
    new Date().getTime() / 1000 -
      configuration.app.bookableInterval.minutes * 60
  );
  dispatch({ type: ADD_ALL_TO_CART_STARTED });
  const promise = await Cart.shipment_add_all();
  if (check(dispatch, promise)) {
    dispatch({
      type: ADD_ALL_TO_CART,
      payload: promise.data.response.data,
      bookable,
      party: "to-be-paid",
    });
  } else {
    dispatch({
      type: ADD_ALL_TO_CART_ERROR,
    });
  }
};

export const removeAllFromCart = () => async (dispatch) => {
  dispatch({ type: REMOVE_ALL_FROM_CART_STARTED });
  const promise = await Cart.shipment_remove_all();
  if (check(dispatch, promise)) {
    dispatch({
      type: REMOVE_ALL_FROM_CART,
      payload: promise.data.response.data,
      party: "to-be-paid",
    });
  } else {
    dispatch({
      type: REMOVE_FROM_CART_ERROR,
    });
  }
};

export const buyCart =
  ({ success_url, cancel_url, description }) =>
  async (dispatch) => {
    dispatch({ type: BUY_CART_STARTED });
    const promise = await Cart.buy({ success_url, cancel_url, description });
    if (check(dispatch, promise)) {
      dispatch({
        type: BUY_CART,
        payload: promise.data.response.data,
      });
      const stripe = window.Stripe(configuration.gateway.stripe.key);
      const { error } = await stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: promise.data.response.data.id,
      });
      if (error) {
        dispatch({ type: ERROR_DISPLAY, payload: error });
      }
    } else {
      dispatch({
        type: BUY_CART_FAILED,
        payload: promise,
      });
    }
  };

export const confirmPayment = (params, callback) => async (dispatch) => {
  dispatch({ type: CONFIRM_PAYMENT_STARTED });
  const promise = await Cart.confirm(params);
  if (check(dispatch, promise)) {
    dispatch({
      type: CONFIRM_PAYMENT,
      payload: promise.data.response.data,
    });
    dispatch(autosense());
    callback();
  } else {
    dispatch({
      type: CONFIRM_PAYMENT_ERROR,
    });
  }
};

export const dismissPayment = (callback) => async (dispatch) => {
  dispatch({ type: DISMISS_PAYMENT_STARTED });
  const promise = await Cart.dismiss();
  if (check(dispatch, promise)) {
    dispatch({
      type: DISMISS_PAYMENT,
      payload: promise.data.response.data,
    });
  } else {
    dispatch({
      type: DISMISS_PAYMENT_ERROR,
    });
  }
  callback();
};

export const deleteDraft =
  ({ shipment_id, activeTab, params, sorts, updateSearchParams }) =>
  async (dispatch) => {
    dispatch({
      type: SHIPMENT_DELETE_STARTED,
      party: "drafts",
      payload: {
        shipment_id,
      },
    });
    const promise = await Shipment.draft_delete(shipment_id);
    if (check(dispatch, promise)) {
      dispatch({
        type: SHIPMENT_DELETE,
        payload: { data: promise.data.response.data },
      });
      dispatch(shipmentSearch(activeTab, params, sorts, updateSearchParams));
    } else {
      dispatch({
        type: SHIPMENT_DELETE_ERROR,
        party: "drafts",
        payload: { shipment_id },
      });
    }
  };

export const subscribe =
  ({
    company_name,
    country,
    postalcode,
    city,
    province,
    address,
    vat_code,
    pec,
    sdi_code,
    bank,
    bank_account_owner,
    bank_account,
  }) =>
  async (dispatch) => {
    const promise = await Subscription.subscribe({
      company_name,
      country,
      postalcode,
      city,
      province,
      address,
      vat_code,
      pec,
      sdi_code,
      bank,
      bank_account_owner,
      bank_account,
    });
    dispatch({
      type: SUBSCRIBE,
      payload: promise,
    });
  };

export const updateUser = (field, value) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: { field, value },
  });
};

export const logError =
  (message, error, info = {}) =>
  async (dispatch) => {
    const promise = await Logger.error(message, error, info);
    console.log(promise);
  };

export const getInvoices = () => async (dispatch) => {
  dispatch({ type: INVOICES_GET_STARTED });
  const promise = await Billing.invoices_get();
  if (check(dispatch, promise)) {
    dispatch({
      type: INVOICES_GET,
      payload: { data: promise.data.response.data },
    });
  } else {
    dispatch({
      type: INVOICES_GET_ERROR,
      payload: promise,
    });
  }
};
