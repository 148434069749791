import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  REDIRECTING,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const redirectReducer = (state = initialState.redirectUrl, action) => {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return action.data;

    case REDIRECTING:
    case SIGNUP_FAILURE:
      return initialState.redirectUrl;

    default:
      return state;
  }
};

export default redirectReducer;
