import _ from "lodash";

import {
  ERROR_DISPLAY,
  SIGNUP_FAILURE,
  LOGOUT,
  VALIDATION_ERROR,
  VALIDATION_SUCCESS,
  ERROR_DISMISS,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

export const errorReducer = (state = initialState.error, action) => {
  switch (action.type) {
    case ERROR_DISPLAY:
    case SIGNUP_FAILURE:
      return action.payload;

    case ERROR_DISMISS:
    case LOGOUT:
      return initialState.error;

    default:
      return state;
  }
};

export const errorsReducer = (state = initialState.errors, action) => {
  const { form, party, field, value, index, min, max } = action.errors || {};
  const clone = _.cloneDeep(state);
  switch (action.type) {
    case VALIDATION_ERROR:
      let error = null;
      if (party === "packages") {
        error = {
          [form]: {
            shipping: {
              [party]: {
                [`index-${index}`]: {
                  [field]: { value, min, max },
                },
              },
            },
          },
        };
      } else {
        error = {
          [form]: {
            [party]: {
              [field]: { value, min, max },
            },
          },
        };
      }
      return _.mergeWith({}, state, error);

    case VALIDATION_SUCCESS:
      if (!party) {
        if (clone[form]) {
          delete clone[form];
        }
      } else if (party === "packages") {
        if (
          clone[form] &&
          clone[form].shipping &&
          clone[form].shipping[party] &&
          clone[form].shipping[party][`index-${index}`] &&
          clone[form].shipping[party][`index-${index}`][field]
        ) {
          delete clone[form].shipping[party][`index-${index}`][field];
          if (
            Object.keys(clone[form].shipping[party][`index-${index}`])
              .length === 0
          ) {
            delete clone[form].shipping[party][`index-${index}`];
            if (clone[form].shipping[party].length === 0) {
              delete clone[form].shipping[party];
              if (Object.keys(clone[form].shipping).length === 0) {
                delete clone[form].shipping;
              }
            }
          }
        }
      } else {
        if (clone[form] && clone[form][party] && clone[form][party][field]) {
          delete clone[form][party][field];
          if (Object.keys(clone[form][party]).length === 0) {
            delete clone[form][party];
          }
        }
      }
      return clone;

    default:
      return state;
  }
};
